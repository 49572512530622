import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getUrl } from "../../hooks/axiosInstance";
const RemoveAsset = ({ getAssetData, title }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [titleInput, setTitleInput] = useState();
  const { account } = useWeb3React();
  const { tokenId } = useParams();
  // const { mediaContract } = useContract();

  // const handlePublishingAsset = async (close) => {
  //   if (account && mediaContract) {
  //     try {
  //       setLoading(true);
  //       const res = await mediaContract.methods
  //         .removeBid(tokenId)
  //         .send({ from: account });

  //       if (res?.status) {
  //         toast.success("Bid removed Successfully.");
  //       }
  //       getAssetData();
  //       setLoading(false);
  //       close();
  //     } catch (err) {
  //       if (err?.message) {
  //         toast.error(err.message);
  //         setLoading(false);
  //         close();
  //       } else {
  //         toast.error("something went wrong");
  //         setLoading(false);
  //         close();
  //       }
  //     }
  //   }
  // };
  const handleRemoveAsset = async (e, close) => {
    e.preventDefault();
    if (titleInput === title) {
      try {
        const res = await axios.post(
          `${getUrl()}/api/deleteNft`,
          {
            tokenId: tokenId,
          },
          { withCredentials: true }
        );
        if (res?.status === 200) {
          toast.success("Asset deleted successfully.");
          setLoading(false);
          close();
          navigate(`/account/${account}`);
        }
      } catch (err) {
        if (err?.response?.data) {
          console.log("err", err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
          setLoading(false);
          close();
        } else {
          toast.error("something went wrong");
          setLoading(false);
          close();
        }
      }
    }
  };
  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
        >
          Remove Asset
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                Are you sure to Remove Asset
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />

              <div style={{ paddingTop: "10px" }}>
                <form
                  onSubmit={(e) => {
                    handleRemoveAsset(e, close);
                    // handlePublishingAsset(e, close);
                  }}
                >
                  <label htmlFor="cover" style={{ padding: "5px 0" }}>
                    Asset Name to verify
                  </label>
                  <input
                    type="text"
                    id="removeAsset"
                    className="bft-input"
                    placeholder="Enter asset Name to remove"
                    name="removeAsset"
                    onChange={(e) => {
                      setTitleInput(e.target.value);
                      if (e.target.value === title) {
                        setError("Title matched.");
                      } else {
                        setError("Title not Matched.");
                      }
                    }}
                    style={{ border: "1px solid gray" }}
                    required
                  />
                  <label
                    style={{
                      color: titleInput === title ? "green" : "red",
                      marginBottom: "10px",
                    }}
                  >
                    {error && error}
                  </label>
                  <input
                    type="submit"
                    className="bft-input bft-btn bft-color"
                    value="Remove Asset"
                    style={{ color: "#fff" }}
                  />
                </form>
              </div>
            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default RemoveAsset;
