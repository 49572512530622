import {
  artifacts,
  mediaContractABI,
  marketContractABI,
  tokenContractABI,
  wBNBAddress,
} from "../../contract-data/contract-data";
import React, { useCallback } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

export function useContract() {
  const { chainId } = useWeb3React();
  const [contract, setContract] = React.useState({
    mediaContract: null,
    marketContract: null,
    tokenContracts: null,
    wBNBContract: null
  });
  const connectContracts = useCallback(() => {
    let { ethereum } = window;
    const web3Provider = new Web3(ethereum);

    const mediaContract = new web3Provider.eth.Contract(
      mediaContractABI,
      artifacts.mediaContractAddress[chainId]
    );
    const marketContract = new web3Provider.eth.Contract(
      marketContractABI,
      artifacts.marketContractAddress[chainId]
    );
    const tokenContracts = new web3Provider.eth.Contract(
      tokenContractABI,
      artifacts.tokenContractAddress[chainId]
    );
    const wBNBContract = new web3Provider.eth.Contract(
      tokenContractABI,
      wBNBAddress
    );
    setContract({ mediaContract, marketContract, tokenContracts,wBNBContract });
  }, [chainId]);

  React.useEffect(() => {
    connectContracts();
  }, [connectContracts]);
  return {
    mediaContract: contract.mediaContract,
    marketContract: contract.marketContract,
    tokenContract: contract.tokenContracts,
    wBNBContract: contract.wBNBContract
  };
}
