import { useWeb3React } from "@web3-react/core";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";
import Wallet from "../sections/wallet";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";

const Header = () => {
  const { active, deactivate, account } = useWeb3React();
  function doUnConnect(e) {
    e.preventDefault();
    document.getElementById("connecttop").style.display = "none";
    document.getElementById("connectbottom").style.display = "none";
  }

  function doConnect(e) {
    e.preventDefault();
    document.getElementById("connecttop").style.display = "block";
    document.getElementById("connectbottom").style.display = "block";
  }

  function disconnectRun() {
    deactivate();
    Swal.fire({
      icon: "warning",
      title: "Disconnected!",
      text: "If you are still logged in please try to disconnect directly from your wallet.",
      footer:
        '<a href="https://help.nzvedazbigfive.io" className="link-dark">Why do I have this issue?</a>',
    });
  }

  function bft_open(e) {
    e.preventDefault();
    document.getElementById("mySidebar").style.display = "block";
    document.getElementById("myOverlay").style.display = "block";
  }

  function bft_close(e) {
    e.preventDefault();
    document.getElementById("mySidebar").style.display = "none";
    document.getElementById("myOverlay").style.display = "none";
  }

  const Greeting = () => {
    var myDate = new Date();
    var hours = myDate.getHours();
    var greet;
    if (hours < 12) greet = "Good Morning";
    else if (hours >= 12 && hours <= 17) greet = "Good Afternoon";
    else if (hours >= 18 && hours <= 20) greet = "Good Evening";
    else if (hours >= 21 && hours <= 24) greet = "Good Night";

    return greet;
  };

  return (
    <>
      <nav
        className="bft-sidebar bft-bar-block bft-collapse bft-animate-left bft-card nav-index bft-scrollinfo"
        id="mySidebar"
        onClick={bft_close}
      >
        <Link
          to={"/"}
          className="bft-bar-item bft-button bft-border-bottom level-top link-dark"
        >
          <img className="bft-large logo" src={Logo} alt="" /> Nzvedaz BFT
        </Link>
        <span className="bft-bar-item bft-button bft-sand bft-hide-large bft-large">
          CLOSE <i className="fa fa-times bft-text-red bft-right"></i>
        </span>
        <Link to={"/"} className="bft-bar-item bft-button link-dark">
          <i className="fa fa-home bft-text"></i> Home
          <i className="fa fa-arrow-right bft-right bft-large"></i>
        </Link>

        {/* <Link to={"/assets/"} className="bft-bar-item bft-button link-dark">
          <i className="fa fa-store bft-text"></i> Assets
          <i className="fa fa-arrow-right bft-right bft-large"></i>
        </Link> */}

        <Link to={"/privacy/"} className="bft-bar-item bft-button link-dark">
          <i className="fa fa-user-secret bft-text"></i> Privacy
          <i className="fa fa-arrow-right bft-right bft-large"></i>
        </Link>

        <Link to={"/terms/"} className="bft-bar-item bft-button link-dark">
          <i className="fa fa-handshake bft-text"></i> Terms
          <i className="fa fa-arrow-right bft-right bft-large"></i>
        </Link>

        <hr />
        <div className="nav-fix bft-padding">
          {active ? (
            <Link
              to={`/account/${account}`}
              style={{ textDecoration: "none" }}
              className="link-dark"
            >
              <i className="fa fa-user bft-text"></i> My Account{" "}
              <i className="fa fa-cog bft-btn bft-right pointer"></i>
            </Link>
          ) : (
            <>
              <p className="pointer">
                <i className="fa fa-wallet bft-text"></i> Connect Wallet{" "}
                <i
                  className="fa fa-sign-in-alt bft-btn bft-right bft-text-green"
                  onClick={doConnect}
                ></i>
              </p>
              <div id="connectbottom" className="bft-modal">
                <div
                  className="bft-modal-content"
                  style={{ maxWidth: "500px" }}
                >
                  <div className="bft-container bft-white bft-padding">
                    <h4>
                      CONNECT YOUR WALLET{" "}
                      <i
                        className="fa fa-times bft-text-red bft-right bft-btn"
                        onClick={doUnConnect}
                      ></i>
                    </h4>
                    <p className="bft-tiny bft-center">
                      Please make sure that you are connected to Binance Smart
                      Chain Network before you attempt to connect your wallet.
                    </p>
                    <hr />
                    <Wallet />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div></div>
      </nav>
      <div
        className="bft-overlay bft-hide-large bft-animate-opacity pointer"
        onClick={bft_close}
        id="myOverlay"
      ></div>
      <header className="bft-main put-margin head-fix">
        <div className="level-top bft-color" style={{ padding: "0.01em 16px" }}>
          <p className="bft-left bft-large">
            <Link to={"/"} className="bft-hide-large unlinkify link-dark">
              <img className="logo-small" src={Logo} alt="" /> BFT Marketplace
            </Link>
            <span className="bft-hide-small bft-hide-medium bft-large">
              <Greeting />
            </span>
          </p>
          <p className="bft-right">
            {active ? (
              <button className="bft-btn bft-small" onClick={disconnectRun}>
                <i className="fa fa-sign-out-alt bft-text-white"></i>
              </button>
            ) : (
              <>
                <button className="bft-btn bft-small">
                  <i
                    className="fa fa-sign-in-alt bft-text-white"
                    onClick={doConnect}
                  ></i>
                </button>
                <div id="connecttop" className="bft-modal">
                  <div
                    className="bft-modal-content"
                    style={{ maxWidth: "500px" }}
                  >
                    <div className="bft-container bft-white bft-padding">
                      <h4>
                        CONNECT YOUR WALLET{" "}
                        <i
                          className="fa fa-times bft-text-red bft-right bft-btn"
                          onClick={doUnConnect}
                        ></i>
                      </h4>
                      <p className="bft-tiny bft-center">
                        Please make sure that you are connected to Binance Smart
                        Chain Network before you attempt to connect your wallet.
                      </p>
                      <hr />
                      <Wallet />
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              className="bft-btn bft-small bft-hide-large"
              onClick={bft_open}
            >
              <i className="fa fa-bars"></i>
            </button>
          </p>
        </div>
      </header>
    </>
  );
};

export default Header;
