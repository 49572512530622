import axios from "axios";

export const getUrl = () => {
  // return "http://localhost:8080";
  return "https://integrated-backend.vercel.app";
  // return "https://nzvedaz-bft.herokuapp.com";
};
export default axios.create({
  // baseURL: "https://nzvedaz-bft.herokuapp.com",
  baseURL: getUrl(),
  // withCredentials: true,
});
