import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import {
  artifacts,
  mediaContractABI,
} from "../contract-data/contract-data";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";


export const injected = new InjectedConnector({
  // supportedChainIds: [56, 97],
  supportedChainIds: [56],
});

export const walletconnect = new WalletConnectConnector({
  // supportedChainIds: [1, 4, 100],
  supportedChainIds: [56],
  qrcode: true,
});
