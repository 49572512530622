import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import MetamaskProvider from "./metamaskProvider";
import Header from "../web/templates/header";
import Footer from "../web/templates/footer";
import Home from "../web/pages/home";
import Shop from "../web/pages/shop";
import Terms from "../web/pages/terms";
import Privacy from "../web/pages/privacy";
import Account from "../web/pages/account";
import Create from "../web/pages/create";
import Assets from "../web/pages/assets";
// import Asset from "../web/pages/asset";
// import Manage from "../web/pages/manage";
import NotFound from "../web/pages/404";
import NftAsset from "../web/pages/NftAsset";
import { Toaster } from "react-hot-toast";

const POLLING_INTERVAL = 12000;
const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetamaskProvider>
        <Router>
          <Header />
          <Toaster position="top-right" reverseOrder={false} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/assets/" element={<Shop />} />
            {/* <Route exact path="/assets/:asset/" element={<Asset />} /> */}
            <Route
              exact
              path="/assets/:tokenAddress/:tokenId"
              element={<NftAsset />}
            />
            <Route exact path="/terms/" element={<Terms />} />
            <Route exact path="/privacy/" element={<Privacy />} />
            <Route exact path="/account/:account" element={<Account />} />
            <Route exact path="/account/create/" element={<Create />} />
            <Route exact path="/account/assets/:address" element={<Assets />} />
            {/* <Route
                exact
                path="/account/assets/:asset/"
                element={<Manage />}
              /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </MetamaskProvider>
    </Web3ReactProvider>
  );
};

export default App;
