import { useWeb3React } from "@web3-react/core"
import { bft_WBNB_PairAddress } from "../../contract-data/contract-data";
import { useContract } from "./use-connector";
import BigNumber from "bignumber.js";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Web3 from "web3";


export const useBftToUsd = () =>{
    const {wBNBContract, tokenContract} = useContract();
    const [price, setPrice] = useState("");

    const bftToUsd = useCallback(async ()=> {
        if(wBNBContract && tokenContract){
            try {
            const wbnbBalanceOfBFTPair = await wBNBContract.methods.balanceOf(bft_WBNB_PairAddress).call();
            console.log("wbnbBalanceOfBFTPair: ", wbnbBalanceOfBFTPair);
    
            const bftBalanceOfBFTPair = await tokenContract.methods.balanceOf(bft_WBNB_PairAddress).call();
            console.log("bftBalanceOfBFTPair: ", bftBalanceOfBFTPair);

            const BNBPrice = await axios.get("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT");
            const bnbToUSD = String(BNBPrice?.data?.price * Web3.utils.fromWei(wbnbBalanceOfBFTPair,"ether"));
            console.log("BNBPrice: ", BNBPrice?.data?.price);
            console.log("bnbToUSD: ", bnbToUSD);
    
            // const p = new BigNumber(wbnbBalanceOfBFTPair).div(bftBalanceOfBFTPair).toNumber();
            const p = new BigNumber(bnbToUSD).div(bftBalanceOfBFTPair / 10 **9).toNumber().toFixed(7);
            console.log("pxxx: ", p);
            setPrice(p);
            }catch(e) {console.log("useBftToUsd error: ", e)}
    
        }
    }, [wBNBContract, tokenContract])
    
    useEffect(() => {
        bftToUsd();
      }, [bftToUsd]);
    
    return price;  

} 