import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Web3 from "web3";
import {
  artifacts
} from "../../../contract-data/contract-data";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import axios from "../../hooks/axiosInstance";
import { useGetBid } from "../../helpers/use-get-bid";
import { useGetBalanceOf } from "../../helpers/use-get-balance";
import { useBftToUsd } from "../../helpers/use-get-bft-To-usd";

const BidAssetModal = ({ tokenId, getAssetData, blockchainPrice }) => {
  const bftPrice = useBftToUsd();
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [alreadyBid, setAlreadyBid] = useState(false);
  const { account,chainId } = useWeb3React();
  const { bids } = useGetBid({ tokenId });
  const { mediaContract, marketContract, tokenContract } = useContract();
  const { balance } = useGetBalanceOf({ account });
  
  let mybid = bids.find(
    (e) => e?.bidder?.toLowerCase() === account?.toLowerCase()
  );
  const checkApproveAllownce = async () => {
    const res = await tokenContract.methods
      .allowance(account, artifacts.marketContractAddress[chainId])
      .call();
    if (Number(res) < price) {
      const approve = await tokenContract.methods
        .approve(artifacts.marketContractAddress[chainId], price)
        .send({ from: account });
      return checkApproveAllownce();
    }
    return res;
  };

  const handlePublishingAsset = async (close) => {
    if (
      tokenContract &&
      account &&
      !mybid &&
      mediaContract &&
      parseInt(balance) > parseInt(0)
    ) {
      try {
        setLoading(true);
        const approve = await checkApproveAllownce();

        if (Number(approve) >= Number(price)) {
          const onBid = await mediaContract.methods
            .setBid(tokenId, [price, artifacts.tokenContractAddress[chainId], account])
            .send({
              from: account,
            });
          if (onBid?.status) {
            toast.success("Successfully Bid on Asset");
            getAssetData();
          }
          setLoading(false);

          close();
        }
      } catch (err) {
        if (err?.message) {
          toast.error(err.message);
          setPrice();
          setLoading(false);
          close();
        }
      }
    }
  };

  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
          disabled={
            blockchainPrice && blockchainPrice?.amount !== "0" ? false : true
          }
        >
          Place A Bid
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                <span>you are about to Bid on Asset using BFT Token</span>
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />
              {/* {parseInt(balance) < parseInt(blockchainPrice?.amount) && (
                <h5 style={{ color: "red" }}>You Have Insufficient Balance</h5>
              )} */}
              {parseInt(balance) == parseInt(0) && (
                <h5 style={{ color: "red" }}>You Have Insufficient Balance</h5>
              )}
              <div style={{ position: "relative" }}>
                {mybid && (
                  <div className="bft-overlay-text">
                    <p>Already bid on that Asset</p>
                  </div>
                )}

                <label htmlFor="cover">Bid Price</label><br/>
                {price > 0 ? <span>{`IN USD: ${(bftPrice * (price / 10 ** 9)).toFixed(7)}`}</span> : null}

                <input
                  type="number"
                  id="price"
                  className="bft-input"
                  placeholder="Enter Price"
                  name="price"
                  onChange={(e) => {
                    // let wePrice = Web3.utils.toWei(
                    //   e.target.value || "0",
                    //   "ether"
                    // );
                    let wePrice = String(e.target.value * 10 ** 9) //actual today
                    setPrice(wePrice);
                  }}
                  style={{ border: "1px solid gray", marginBottom: "10px" }}
                  required
                />
                <button
                  className="bft-input bft-btn bft-color"
                  onClick={() => {
                    handlePublishingAsset(close);
                  }}
                  // disabled={
                  //   parseInt(balance) < parseInt(blockchainPrice?.amount)
                  // }
                  disabled={
                    parseInt(balance) == parseInt(0)
                  }
                >
                  Place a Bid
                </button>
              </div>
            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default BidAssetModal;
