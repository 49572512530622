import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Helmet } from "react-helmet";
import Wallet from "../sections/wallet";
import Popup from "reactjs-popup";
import { Col, Container, Row } from "react-bootstrap";
import { getEllips } from "../helpers/common";
import { useContract } from "../helpers/use-connector";
import { CgDetailsMore } from "react-icons/cg";
import { TiEdit } from "react-icons/ti";
import { MdFormatListBulleted } from "react-icons/md";
import { FaTag } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios, { getUrl } from "../hooks/axiosInstance";
import axiosOriginal from "axios";
import Skeleton from "react-loading-skeleton-2";
import Loader from "../loader";
import Web3 from "web3";
import { tokenContract } from "../../contract-data/contract-data";
import PublishAssetModal from "../components/PublishAssetModal";
import toast from "react-hot-toast";
import getUser from "../helpers/use-get-user";
import { useGetPrice } from "../helpers/use-get-price";
import UnPublishModal from "../components/UnPublishModal.js";
import PurchaseNowModal from "../components/PurchaseNowModal";
import BidAssetModal from "../components/BidAssetModal";
import { useGetBid } from "../helpers/use-get-bid";
import BidsTables from "../components/BidsTable";

import RemoveAsset from "../components/RemoveAsset";
import EditDescription from "../components/EditDescription";

import { useBftToUsd } from "../helpers/use-get-bft-To-usd";

const NftAsset = () => {
  const navigate = useNavigate();
  const { account, active } = useWeb3React();
  const { tokenAddress, tokenId } = useParams();
  const [owner, setOwner] = useState();
  const [nftData, setNftData] = useState();
  const [loading, setLoading] = useState(false);
  // const [fileType, setFileType] = useState();
  const [minter, setMinter] = useState();
  const { mediaContract, marketContract, tokenContract } = useContract();
  const { price } = useGetPrice({ tokenId, nftData });
  const [tokenCreator,setTokenCreator] = useState("");// new work 15OCT

  const bftPrice = useBftToUsd();
  // console.log("bftPrice: ", bftPrice);

  useEffect(async () => {
    if (mediaContract) {
      try {
        setLoading(true);
        let owner;
        const actualOwner = await mediaContract.methods.ownerOf(tokenId).call(); //actual owner
        const newOwner = await marketContract.methods.getSeller(tokenId).call(); // owner in market contract
        const addressZero = "0x0000000000000000000000000000000000000000";
        if (newOwner == addressZero) {
          owner = actualOwner;
        } else {
          owner = newOwner;
        }

        const resOwner = await axios.get(`/api/signin?address=${owner}`);
        if (resOwner?.status === 200) {
          setOwner(resOwner?.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("err [item page user geting err]", err);
      }
    }
  }, [mediaContract, tokenId, price]);
  useEffect(async () => {
    if (nftData?.nftMinter) {
      try {
        setLoading(true);

        const resOwner = await axiosOriginal.get(
          `${getUrl()}/api/signin?address=${nftData?.nftMinter}`,
          { withCredentials: true }
        );
        if (resOwner?.status === 200) {
          setMinter(resOwner?.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("err [item page user geting err]", err);
      }
    }
  }, [nftData]);

  // useEffect(async () => {
  //   if (!(nftData?.metadata?.mediaType === "application/pdf")) {
  //     try {
  //       const toDataURL = async (url) => {
  //         fetch(url)
  //           .then((response) => response.blob())
  //           .then((blob) => {
  //             console.log("blob", blob);
  //             setFileType(blob.type.split("/")[0]);
  //           });
  //       };

  //       if (nftData?.metadata?.image) {
  //         toDataURL(nftData?.metadata?.image);
  //       } else {
  //         console.log(
  //           "else nftData?.metadata?.image::::",
  //           nftData?.metadata?.image
  //         );
  //       }
  //     } catch (err) {
  //       console.log("err file error", err);
  //     }
  //     return setFileType("");
  //   }
  // }, [nftData]);
  const getAssetData = async () => {
    try {
      setLoading(true);

      const res = await axios.post("/api/getnft", { tokenId });
      setNftData(res?.data?.nft);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err [fetching nft error]", err?.reponse, err);
    }
  };
  useEffect(() => {
    getAssetData();

    return setNftData([]);
  }, [tokenId]);

  // new work 15OCT
  const getTokenCreator = async () => {
    try {
      setLoading(true);

      const res = await mediaContract?.methods?.tokenCreators(tokenId).call();
      setTokenCreator(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err getTokenCreator", err?.reponse, err);
    }
  };
  useEffect(() => {
    getTokenCreator();

    return setTokenCreator("");
  }, [tokenId,mediaContract]);

  // useEffect(async () => {
  //   if (marketContract) {
  //     try {
  //       const rescontract = await marketContract.methods
  //         .currentAskForToken(tokenId)
  //         .call();
  //     } catch (err) {
  //       console.log("err [fetching marketContract error]", err);
  //     }
  //   }
  // }, [tokenId, marketContract]);

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Asset::Nzvedaz BFT Marketplace</title>
      </Helmet>
      {active ? (
        !loading ? (
          <div className="bft-section">
            <Container fluid>
              {/*  className="gx-1"*/}
              <Row>
                {/* lest  section design  */}
                <Col sm={12} md={8}>
                  <div>
                    <div>
                      <div className="bft-padding">
                        <h3 style={{ fontWeight: 700 }}>
                          #{tokenId}- {nftData?.metadata?.name}
                        </h3>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                    </div>
                    <hr style={{ margin: 0 }} />
                    <div className="bft-nftOwner-row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          className="bft-circle"
                          src={owner?.avatar ? owner.avatar : "/testnft.jpg"}
                          width={50}
                          height={50}
                        />
                        <p style={{ marginLeft: "10px", fontWeight: 700 }}>
                          Owner:{" "}
                          <span
                            className="bft-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/account/${owner?.address}`);
                            }}
                          >
                            {owner?.name}
                          </span>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="bft-nft-price-box">
                      <div
                        className="bft-card-2 bft-hover-shadow  bft-round-large"
                        style={{ overflow: "hidden", position: "relative" }}
                      >
                        {owner &&
                          owner?.address?.toLowerCase() !==
                            account?.toLowerCase() &&
                          parseInt(price?.amount) === 0 && (
                            <div className="bft-overlay-text">
                              <p>Asset not for sell</p>
                            </div>
                          )}
                        <div className="bft-medium bft-padding-large">
                          <p>Current Price</p>
                          <div
                            className="bft-section"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src="/logo.png" width={50} height={50} />
                            <h5
                              style={{
                                margin: 0,
                                marginLeft: "10px",
                                padding: 0,
                                flexGrow: 1,
                              }}
                            >
                              {price && price?.amount !== "0"
                                // ? Web3.utils.fromWei(price?.amount, "ether")
                                ? String(price?.amount / 10 ** 9) //actual today
                                : "--"}{" "}
                              {/* BFT {`($ ${ ((price?.amount / 10 ** 9) * bftPrice).toFixed(7)})`} */}
                              BFT {`(${ ((price?.amount / 10 ** 9) * bftPrice)}$)`}
                            </h5>
                          </div>
                          {/* price section */}
                          <div style={{ display: "flex", gap: "10px" }}>
                            {owner &&
                            owner?.address?.toLowerCase() ===
                              account?.toLowerCase() ? (
                              <>
                                {price && price?.amount !== "0" ? (
                                  <UnPublishModal
                                    tokenId={tokenId}
                                    getAssetData={getAssetData}
                                  />
                                ) : (
                                  <>
                                    <PublishAssetModal
                                      tokenId={tokenId}
                                      getAssetData={getAssetData}
                                    />
                                    {/* // new work 15OCT */}
                                    {nftData?.nftMinter &&
                                      nftData?.nftMinter?.toLowerCase() ===
                                        account?.toLowerCase() && tokenCreator?.toLowerCase() ===
                                        account?.toLowerCase() && (
                                        <RemoveAsset
                                          title={nftData.metadata.name}
                                          getAssetData={getAssetData}
                                        />
                                      )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <PurchaseNowModal
                                  tokenId={tokenId}
                                  getAssetData={getAssetData}
                                  blockchainPrice={price}
                                />
                                <BidAssetModal
                                  tokenId={tokenId}
                                  getAssetData={getAssetData}
                                  blockchainPrice={price}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* listing section  */}
                    <div style={{ margin: "16px 0" }}>
                      <div
                        className="bft-padding-small bft-color"
                        style={{ borderRadius: "5px 5px 0 0" }}
                      >
                        <h5>
                          <MdFormatListBulleted
                            style={{ paddingLeft: "5px", fontSize: "28px" }}
                          />{" "}
                          Listing
                        </h5>
                      </div>
                      <div
                        className="bft-padding-small  bft-hover-shadow"
                        style={{
                          borderRadius: "5px 5px 0 0",
                          wordBreak: "break-all",
                        }}
                      >
                        <table className="bft-nft-table">
                          <tbody>
                            <tr>
                              <th>Price</th>
                              <th>From</th>
                              <th>Address</th>
                            </tr>
                            <tr>
                              <td>00 bft</td>
                              <td
                                onClick={() => {
                                  navigate(`/account/${minter?.address}`);
                                }}
                                className="bidtable-td"
                              >
                                {minter?.name}
                              </td>
                              <td>
                                {getEllips(
                                  nftData?.nftMinter
                                    ? nftData?.nftMinter
                                    : "00000000000000000000000000x0000"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* bids table section  */}
                    <div style={{ margin: "16px 0" }}>
                      <div
                        className="bft-padding-small bft-color"
                        style={{ borderRadius: "5px 5px 0 0" }}
                      >
                        <h5>
                          <FaTag
                            style={{ paddingLeft: "5px", fontSize: "28px" }}
                          />{" "}
                          Bids
                        </h5>
                      </div>
                      <div
                        className="bft-padding-small  bft-hover-shadow manipulate-table-scroll"
                        style={{
                          borderRadius: "5px 5px 0 0",
                          wordBreak: "break-all",
                          overflow: "auto",
                        }}
                      >
                        <BidsTables
                          owner={owner?.address}
                          getAssetData={getAssetData}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {/* right section design  */}
                <Col sm={12} md={4}>
                  <div
                    className="bft-card-2 bft-hover-shadow"
                    style={{ borderRadius: "10px", overflow: "hidden" }}
                  >
                    {nftData?.metadata?.mediaType === "image" ? (
                      <img
                        src={nftData?.metadata?.image}
                        style={{
                          height: "auto",
                          width: "100%",
                        }}
                      />
                    ) : nftData?.metadata?.mediaType === "video" ? (
                      <video height="auto" controls className="bft-block">
                        <source
                          src={nftData?.metadata?.image}
                          type="video/mp4"
                        />
                      </video>
                    ) : nftData?.metadata?.mediaType === "audio" ? (
                      <audio
                        style={{ width: "100%" }}
                        src={nftData.metadata.image}
                        controlsList="nodownload"
                        controls
                      ></audio>
                    ) : nftData?.metadata?.mediaType === "pdf" ? (
                      <embed
                        src={nftData.metadata.image}
                        width={"100%"}
                        height={"500px"}
                      />
                    ) : (
                      <Skeleton width="100%" height="500px" />
                    )}
                  </div>
                  <div style={{ margin: "16px 0" }}>
                    <div
                      className="bft-padding-small bft-color"
                      style={{
                        borderRadius: "5px 5px 0 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>
                        <CgDetailsMore
                          style={{ paddingLeft: "5px", fontSize: "28px" }}
                        />{" "}
                        Description
                      </h5>

                      {nftData?.nftMinter &&
                      owner?.address?.toLowerCase() ===
                      account?.toLowerCase() && tokenCreator?.toLowerCase() ===
                      account?.toLowerCase() && 
                      <EditDescription
                      getAssetData={getAssetData}
                      description={nftData?.metadata?.description || " work"}
                      />}
                    </div>
                    <div
                      className="bft-padding-small  bft-hover-shadow"
                      style={{
                        borderRadius: "5px 5px 0 0",
                        wordBreak: "break-all",
                      }}
                    >
                      <p>{nftData?.metadata?.description}</p>
                    </div>
                  </div>
                  <div style={{ margin: "16px 0" }}>
                    <div
                      className="bft-padding-small bft-color"
                      style={{ borderRadius: "5px 5px 0 0" }}
                    >
                      <h5>
                        <CgDetailsMore
                          style={{ paddingLeft: "5px", fontSize: "28px" }}
                        />{" "}
                        Details
                      </h5>
                    </div>
                    <div
                      className="bft-padding-10  bft-hover-shadow"
                      style={{
                        borderRadius: "5px 5px 0 0",
                        wordBreak: "break-all",
                      }}
                    >
                      <table
                        className="table"
                        style={{ borderCollapse: "inherit" }}
                        width={"100%"}
                      >
                        <tbody>
                          <tr>
                            <th>Contract Address</th>
                            <td className="table-td">
                              {getEllips(tokenAddress)}
                            </td>
                          </tr>
                          <tr>
                            <th>token Id</th>
                            <td className="table-td">{tokenId}</td>
                          </tr>
                          <tr>
                            <th>Blockchain</th>
                            <td className="table-td">Binance Smart Chain</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="bft-container bft-content bft-center">
            <Loader />
          </div>
        )
      ) : (
        <div className="bft-section">
          <div className="bft-container">
            <div className="bft-content bft-center">
              <h1>
                You must be logged in with your wallet to access this page!
              </h1>
              <Popup
                modal={true}
                trigger={
                  <button className="bft-btn bft-green bft padding-large">
                    CONNECT YOUR WALLET
                  </button>
                }
                position="center"
              >
                <Wallet />
              </Popup>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NftAsset;
