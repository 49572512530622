import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import Web3 from "web3";
import { artifacts } from "../../../contract-data/contract-data";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import axios from "../../hooks/axiosInstance";
import { useParams } from "react-router-dom";
import { useGetBalanceOf } from "../../helpers/use-get-balance";

const PurchaseNowModal = ({ getAssetData, blockchainPrice }) => {
  const [loading, setLoading] = useState(false);
  const { account, chainId } = useWeb3React();
  const { tokenId } = useParams();
  const { mediaContract, tokenContract } = useContract();
  const { balance } = useGetBalanceOf({ account });

  const checkApproveAllownce = async () => {
    const res = await tokenContract.methods
      .allowance(account, artifacts.marketContractAddress[chainId])
      .call();
    if (Number(res) < blockchainPrice?.amount) {
      const approve = await tokenContract.methods
        .approve(
          artifacts.marketContractAddress[chainId],
          blockchainPrice?.amount
        )
        .send({ from: account });
      return checkApproveAllownce();
    }
    return res;
  };

  const handlePublishingAsset = async (close) => {
    if (
      tokenContract &&
      account &&
      mediaContract &&
      parseInt(balance) >= parseInt(blockchainPrice?.amount)
    ) {
      try {
        setLoading(true);
        const approve = await checkApproveAllownce();
        // e.preventDefault();
        // close();
        if (Number(approve) >= Number(blockchainPrice?.amount)) {
          const onBid = await mediaContract.methods
            .setBid(tokenId, [
              blockchainPrice?.amount,
              artifacts.tokenContractAddress[chainId],
              account,
            ])
            .send({
              from: account,
            });
          if (onBid?.status) {
            setLoading(false);
            const res = await axios.post("/api/nft", {
              tokenId,
              isOnSell: false,
            });
            toast.success("Successfully purchased Asset");
            getAssetData();
          }
          if (loading) setLoading(false);

          close();
        }
      } catch (err) {
        if (err?.message) {
          console.log("purchase if error", err);
          toast.error(err.message);
          setLoading(false);
          close();
        } else {
          console.log("purchase else error", err);
          setLoading(false);
          close();
        }
      }
      if (loading) setLoading(false);
    }
  };

  // console.log("balance: ", balance)
  // console.log("blockchainPrice.amount: ", blockchainPrice.amount)
  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
          disabled={
            blockchainPrice && blockchainPrice?.amount !== "0" ? false : true
          }
        >
          Purchase Now
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                you are about to Purchase Asset using BFT Token
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />
              {parseInt(balance) < parseInt(blockchainPrice?.amount) && (
                <h5 style={{ color: "red" }}>You Have Insufficient Balance</h5>
              )}
              <div>
                <p>
                  Price:{" "}
                  {blockchainPrice &&
                    blockchainPrice?.amount !== "0" &&
                    // Web3.utils.fromWei(blockchainPrice?.amount, "ether")}{" "}
                    String(blockchainPrice?.amount / 10 ** 9)}{" "}
                  BFT
                </p>

                <button
                  className="bft-input bft-btn bft-color"
                  style={{ marginTop: "15px" }}
                  onClick={() => {
                    handlePublishingAsset(close);
                  }}
                  disabled={
                    parseInt(balance) < parseInt(blockchainPrice?.amount)
                  }
                >
                  Purchase Now
                </button>
              </div>
            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default PurchaseNowModal;
